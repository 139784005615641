import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import './ImageSlider.css';
const ImageSlider = () => {
  return (
    <div>
      <AliceCarousel autoPlay autoPlayInterval="3000">
      <img src="/images/img-1.jpeg" className="sliderimg"/>
      <img src="/images/img-2.jpeg" className="sliderimg"/>
      <img src="/images/img-3.jpeg" className="sliderimg"/>
      {/* <img src="/images/img-4.jpg" className="sliderimg"/>
      <img src="/images/img-4.jpg" className="sliderimg"/> */}
    </AliceCarousel>
    </div>
  );
}

export default ImageSlider;