import React from 'react'
import './Question.css';

function Question() {
    return (
    <div class="q-container">
        <div class="q-main">
            <div class="q-content">
                <h3>Have Any Questions? </h3>
                <p>We are here to help. Please fell free to contact us.</p>
            </div> 
            <div class="q-btn" >
                <a href="mailto:iedc@gectcr.ac.in"><button>Contact Us</button></a> 
            </div>
        </div>
    </div>
        
    )
}

export default Question;
